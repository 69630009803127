// old colors
// $primary: #142E43;
// $accent: #52D3AF;
// $warn: #FF4D00;
// $background: #e5f1f9;
$green: #337f22;

// new colors
$primary: #314E5D;
$background: #142E43;
$accent: #20C0CB;
$warn: #E14C60;
$theme-white: #F7FAFC;

$new-primary: #39586A;
$second-primary: #314F63;
$border-color: #132940;
$theme-select: #456274;

$theme-red: rgba(200, 66, 66, 1);
$theme-yellow: rgba(200, 132, 66, 1);
$theme-green: rgba(51, 127, 34, 1);
