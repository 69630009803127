/* You can add global styles to this file, and also import other style files */
@import 'quill/dist/quill.snow.css';
@import 'variables.scss';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Round|Material+Icons+Outlined");

// @import '~ng-pick-datetime/assets/style/picker.min.css';

utax-logist {
  height: calc(100% - 61px);
  display: flex;
  flex-direction: column;
}

html,
body {
  height: 100%;
  min-width: 1366px;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  background-color: $background;
  overflow-y: hidden;
}

button {
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  cursor: pointer;
}

// login spinner style settings
.black-overlay {
  position: absolute !important;
}

.multiple-select-content {
  background: #253D4A;
  max-height: 480px !important;

  border-radius: 8px;
  &::-webkit-scrollbar {
    background-color: #132940;
  }

  .mat-option {
    letter-spacing: 1px;
    font-weight: 300;
    background-color: #1C334A;
    border-bottom: 2px solid #132940;
    &:hover {
      background-color: #1C334A !important;
    }

    .mat-pseudo-checkbox {
      border: 1px solid #314e5d;
      background-color: transparent;

      &.mat-pseudo-checkbox-checked {
        border-color: transparent;
        background-color: $accent;
        &:after {
          top: 3.4px;
          left: 3px;
          border-width: 1.5px;
        }
      }
      &.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
        background: #b0b0b0;
      }
    }

    &.mat-active {
      color: #ffffff;

      //&:hover {
      //  background-color: rgba(0, 0, 0, 0.04) !important;
      //}
    }

    //&:hover {
    //  background-color: rgba(0, 0, 0, 0.04) !important;
    //}
    //&.mat-option-disabled:hover{
    //  background-color: rgba(0, 0, 0, 0) !important;
    //}
  }
}

.roles-mat-select-search {
  width: calc(100% - 20px);
  .mat-datepicker-content {
    background-color: #132940;
  }
  .mat-icon {
    color: white;
  }
  input {
    caret-color: white;
    color: white;
  }
  input::placeholder {
    opacity: 0.5;
    color: white;
  }
}

.dispatcher-select-brigade {
  max-height: 480px !important;
}

.mat-select-panel-wrap {
  .mat-select-panel {
    .mat-selected {
      .mat-option-text {
        color: #ffffff;
      }
    }
  }
}

.hover {
  &:hover {
    color: $accent !important;
    cursor: pointer;
  }
}

.selected {
  background: rgba($background, 0.06);
}

.hold {
  color: $accent !important;
}

.hold-disp {
  color: rgba(200, 132, 66, 1);
}

.viewport {
  height: 100%;
  .cdk-virtual-scroll-content-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.unassign-modal-container .mat-dialog-container,
.remove-order-modal-container .mat-dialog-container,
.refilling-modal-container .mat-dialog-container,
.start-shift-modal-container .mat-dialog-container,
.finish-shift-modal-container .mat-dialog-container,
.refill-modal-container .mat-dialog-container,
.finish-washing-modal-container .mat-dialog-container,
.start-washing-modal-container .mat-dialog-container,
.start-rest-modal-container .mat-dialog-container,
.yes-no-modal-container .mat-dialog-container,
.confirm-modal-container .mat-dialog-container,
.save-order-confirm-modal-container .mat-dialog-container,
.manual-start-finish-shift-modal-container .mat-dialog-container,
.ok-modal-container .mat-dialog-container,
.assign-cab-modal-container .mat-dialog-container,
.message-to-driver-modal-container .mat-dialog-container,
.driver-partners-modal-container .mat-dialog-container,
.create-user-modal-container .mat-dialog-container,
.create-role-modal-container .mat-dialog-container,
.call-driver-modal-container .mat-dialog-container,
.create-disp-modal-container .mat-dialog-container,
.phone-number-verification-dialog .mat-dialog-container ,
.confirm-delete-dialog .mat-dialog-container {
  background: $background;
}

.transfer-call-modal-container .mat-dialog-container,
.additional-options-modal-container .mat-dialog-container,
.custom-translation-modal-container .mat-dialog-container,
.create-model-modal-container .mat-dialog-container,
.create-brand-modal-container .mat-dialog-container,
.transfer-auto-modal-container .mat-dialog-container {
  background: $primary;
}

.delete-confirmation-modal-container .mat-dialog-container {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  border-radius: 8px;
  background: $theme-select;
}

.search-vehicle-dialog-container .mat-dialog-container {
  padding: 0;
  border-radius: 8px;
  background: $background;
}

.full-screen-dialog .mat-dialog-container {
  padding: 0;
  border-radius: 8px;
  background: $new-primary;
  overflow: hidden;
}

.transfer-auto-modal-container{
  padding: 0;
  max-height: 400px;
  overflow-y: scroll;
}

.delete-brand-modal-container .mat-dialog-container {
  padding: 0;
  border-radius: 8px;
}

.order-form-modal-container .mat-dialog-container {
  max-width: 100vw !important;
  padding: 0;
}

.create-disp-modal-container .mat-dialog-container,
.transfer-auto-modal-container .mat-dialog-container {
  padding: 0;
}

.background-panel.mat-select-panel {
  background: $background;
  mat-option {
    color: $theme-white !important;
  }
}

.driver-select-service-panel.mat-select-panel {
  max-height: 480px;
}

.driver-nine-item-panel.mat-select-panel,
.driver-nine-item-panel.mat-autocomplete-panel {
  max-height: 432px;
}

.commission-scale-type-panel.mat-select-panel {
  max-height: 300px;
}

.driver-partners-modal-container .mat-dialog-container {
  border: 3px solid $background;
  position: absolute;
  left: 250px;
  top: 130px;
  max-height: 80%;
  height: auto;
  width: auto;
}

.addresses-container .mat-step {
  background: $background;
}
.addresses-container .mat-step-icon-state-number {
  color: $background;
  background: $background;
}
.addresses-container .mat-stepper-vertical-line::before {
  border-left-width: 2px;
  border-left-style: dashed;
}
.addresses-container .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.5);
}
.addresses-container .mat-step-label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .mat-step-text-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.addresses-container .mat-step-icon-state-end-point,
.addresses-container .mat-step-icon-state-start-point {
  background: $background;
}

.addresses-container .mat-step-icon {
  background: $background;
}

.addresses-container .mat-vertical-content {
  padding: 0;
}
.addresses-container .mat-step-icon .mat-icon {
  height: 13px;
  width: 13px;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
  min-width: min-content;
}

.snackbar {
  background: $background;
}

.snackbar-error {
  background: $warn;
  color: white;
}

.snackbar-with-callway {
  background: $background;
  top: 150px;
  position: absolute;
  left: 40%;
}

.tip {
  padding: 1px;
  position: absolute;
  left: 38px;
  top: -10px;
  border: 1px solid;
  border-radius: 2px;
  font-weight: bold;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.736842px;
  // color: $accent;
  color: $theme-white;
}

.tip-bigger {
  padding: 2px;
  font-size: 11px;
  line-height: 13px;
  position: absolute;
  left: 195px;
  top: -15px;
  border: 1px solid;
  border-radius: 2px;
  font-weight: bold;
  letter-spacing: 0.736842px;
  color: $accent;
}

.tip-disabled {
  color: rgba($accent, 0.5);
}

.tip-alter {
  font-size: 8px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: $primary;
  text-align: center;
  border: 1px solid black;
  padding: 0 2px;
  border-radius: 3px;
}

.my-mini-fab {
  width: 32px !important;
  height: 32px !important;
  line-height: 16px !important;
  font-size: 16px;

  .mat-button-wrapper {
    line-height: 16px !important;
    padding: 0 !important;

    .mat-icon {
      font-size: 16px;
      height: 20px;
      width: 20px;
    }
  }
}

.archive-details-group,
.logist-details-group,
.dispatcher-details-group,
.driver-tariff-form-group {
  .mat-tab-body-wrapper {
    height: 100%;
  }

  .mat-ink-bar {
    // height: 4px !important;
  }
}

.dispatcher-details-group {
  .mat-tab-body-content {
    overflow: hidden !important;
  }
  .mat-tab-label {
    min-width: 100px !important;
    padding: 0 8px !important;
    .mat-tab-label-content {
      white-space: break-spaces;
    }
  }
}
.dispatcher-details-scroll {
  .mat-tab-body-content {
    overflow: auto !important;
  }
}

.driver-tariff-form-group {
  margin-right: 16px;
}

.logist-menu-list {
  .mat-form-field-label-wrapper {
    left: -7px !important;
    top: -7px !important;
  }

  .mat-list-item-content {
    padding: 0 10px !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 14px !important;
  }
}

.disp-menu-list {
  .mat-list-item-content {
    padding: 0 10px !important;
  }
}

.archive-controlmenu,
.autocolumn-controlmenu {
  mat-form-field {
    width: 150px;

    .mat-form-field-underline {
      color: $primary;
    }

    .mat-select-arrow {
      color: $accent;
    }

    .mat-form-field-wrapper {
      margin-top: 7px;
    }
  }
}

.archive-controlmenu,
.autocolumn-controlmenu {
  .mat-select-value {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #ffffff;
    line-height: 22px;
  }
}

.archive-form-container {
  .search-input {
    width: 550px;
  }
}

.archive-panel.mat-select-panel {
  background: $primary;
}

.archive-panel .mat-option-text {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #fff;
  line-height: 22px;
}

.archive-details-info-container {
  .passenger,
  .driver {
    mat-expansion-panel {
      background: $primary;
      border: none;
      box-shadow: none;
      color: #fff;

      mat-expansion-panel-header {
        padding: 0 !important;
      }

      mat-panel-title {
        font-weight: 500;
        font-size: 21px;
        line-height: 29px;
        color: #fff;
        width: 40%;
        margin: 0;
        flex-grow: 0;
      }

      mat-panel-description {
        position: relative;
        color: #fff;
        font-size: 21px;
        line-height: 29px;
      }

      p {
        color: #fff;
        font-size: 21px;
        line-height: 29px;
        margin-left: 40% !important;
      }

      mat-icon {
        position: absolute;
        right: 35px;
        width: 9px;
        height: 9px;
      }

      .mat-expansion-panel-body {
        padding: 0 0 16px;
        color: #fff;
      }
    }
  }
}

.dispatcher-details-passenger-container,
.dispatcher-details-driver-container {
  mat-expansion-panel {
    background: $primary;
    border: none;
    box-shadow: none !important;
    color: #fff;

    mat-expansion-panel-header {
      padding: 0 !important;
      height: 100% !important;
      &:hover {
        background: rgba(255, 255, 255, 0.2) !important;
      }
    }

    mat-panel-title {
      font-weight: 500;
      font-size: 21px;
      line-height: 29px;
      color: #fff;
      width: 50%;
      margin: 0;
      flex-grow: 0;
    }

    mat-panel-description {
      position: relative;
      color: #fff;
      font-size: 21px;
      line-height: 29px;
    }

    p {
      color: #fff;
      font-size: 21px;
      line-height: 29px;
      margin-left: 50% !important;
    }

    mat-icon {
      position: absolute;
      right: -15px;
      width: 9px;
      height: 9px;
    }

    .mat-expansion-panel-body {
      padding: 0 0 16px;
      color: #fff;
    }
  }
}

.disabled-select-white {
  .mat-select-value {
    color: rgba($theme-white, 0.38);
  }

  .mat-select-arrow {
    color: rgba($accent, 0.5) !important;
  }

  mat-form-field {
    color: rgba($theme-white, 0.38);
  }

  .ng-star-inserted {
    color: rgba($theme-white, 0.54);
  }

  .mat-form-field-underline {
    background-image: linear-gradient(
      to right,
      rgba($theme-white, 0.42) 0%,
      rgba($theme-white, 0.42) 100%,
      transparent 0%
    ) !important;
  }
}

.select-white {
  .mat-select-value {
    color: $theme-white;
  }

  .mat-select-arrow {
    color: $accent !important;
  }

  .ng-star-inserted {
    color: $theme-white;
  }

  .mat-form-field-underline {
    background-image: linear-gradient(
      to right,
      rgba($theme-white, 1) 0%,
      rgba($theme-white, 1) 100%,
      transparent 0%
    ) !important;
  }

  .mat-form-field-ripple {
    background: $accent !important;
  }
}

.input-white-order {
  max-height: 52px;
}

.input-white,
.address-form-field {
  .mat-input-element {
    color: $theme-white;
  }

  .ng-star-inserted {
    color: $theme-white;
  }

  .mat-form-field-underline {
    background-image: linear-gradient(
      to right,
      rgba($theme-white, 1) 0%,
      rgba($theme-white, 1) 100%,
      transparent 0%
    ) !important;
  }

  .mat-form-field-ripple {
    background: $accent !important;
  }

  mat-error {
    color: $warn !important;
  }
  mat-hint {
    color: $theme-white !important;
  }

  input {
    caret-color: auto;
  }

  input::placeholder {
    color: #7798AC;
  }

  &.block {
    display: block;
  }
}

.input-white.mat-form-field-invalid .mat-form-field-ripple{
  background-color: $warn !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $theme-white !important;
}

.primary-input {
  .mat-form-field-underline {
    height: 2px !important;
    background-image: linear-gradient(
      to right,
      rgba($primary, 1) 0%,
      rgba($primary, 1) 100%,
      transparent 0%
    ) !important;
  }
}

// .input-white-v2 {
//   .mat-input-element {
//     color: $theme-white;
//   }

//   .ng-star-inserted {
//     color: $theme-white;
//   }

//   .mat-form-field-underline {
//     background-images: linear-gradient(
//       to right,
//       rgba($theme-white, 1) 0%,
//       rgba($theme-white, 1) 100%,
//       transparent 0%
//     ) !important;
//   }

//   .mat-form-field-ripple {
//     background: $accent !important;
//   }

//   mat-error {
//     color: $warn !important;
//   }
// }

.hide-focus.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:$theme-white
}

.hide-focus.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color: $theme-white;
  background-color: $theme-white;
}

.hide-focus.mat-radio-button.mat-accent .mat-radio-outer-circle{
  border-color: $new-primary;
}

.hide-focus.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  display: none;
}

.panel-fix-day.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: #1C334A;
}

.disabled-input-white {
  .mat-input-element {
    color: rgba($theme-white, 0.38) !important;
  }

  .ng-star-inserted {
    color: rgba($theme-white, 0.54) !important;
  }

  .mat-form-field-underline {
    background-image: linear-gradient(
      to right,
      rgba($theme-white, 0.42) 0%,
      rgba($theme-white, 0.42) 100%,
      transparent 0%
    ) !important;
  }
}

.custom-textarea {
  textarea {
    overflow: hidden !important;
    background: rgba($background, 0.4) !important;
    padding: 10px 10px 25px 10px !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
  }
  .mat-form-field-underline {
    width: calc(100% - 20px) !important;
    margin: 0 0 15px 10px !important;
  }
}

.order-type-form-time,
.order-type-form-date {
  .mat-input-element {
    color: $theme-white;
  }
  .ng-star-inserted {
    color: $theme-white;
  }

  .mat-form-field-infix {
    padding-top: 0;
    border: none;
  }

  .mat-form-field-ripple {
    background: $accent !important;
  }

  .mat-form-field-subscript-wrapper {
    width: max-content;
  }

  mat-error {
    color: $warn !important;
  }
}

.order-type-form-date {
  .mat-form-field-underline {
    display: linear-gradient(to right, rgba($background, 1) 0%, rgba($background, 1) 100%, transparent 0%) !important;
  }
}

.order-type-form .mat-radio-container {
  display: none;
}

.order-type-form .mat-radio-label-content {
  padding: 0;
}

.mat-checkbox-frame {
  border-color: $theme-white;
}

.order-type-form .mat-checkbox-frame,
.estimate-container .mat-checkbox-frame {
  border-color: $accent;
}

.order-form-comments-container {
  .mat-form-field-underline {
    background: $background;
  }
}

.background-primary-select.mat-select-panel {
  background: $primary;
  border-radius: 0px;
}
.background-primary-select .mat-option-text {
  color: $background;
}

.mat-select-value-text,
.mat-select-arrow,
.mat-select-placeholder,
.mat-select-value {
  color: $theme-white !important;
  caret-color: $theme-white;
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
  color: $theme-white !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: $theme-white !important;
}

.background-primary-select .mat-option:hover {
  background: rgba($accent, 0.12);
}

.background-primary-select .mat-option.mat-selected.mat-active {
  background: rgba($accent, 0.4);
}

.mat-autocomplete-panel,
.mat-select-panel {
  background: $primary;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  .mat-option {
    color: $theme-white;
    &:hover {
      background: rgba($accent, 0.12);
    }
  }
}

.mat-autocomplete-panel .mat-active {
  background: rgba($accent, 0.4) !important;
}

.mat-autocomplete-panel .mat-option.mat-selected {
  background: rgba($accent, 0.4) !important;
}

.addresses-autocomplete {
  max-height: 430px !important;
}

.mat-autocomplete-0 {
  max-height: 460px !important;
}

.mat-autocomplete-1 {
  max-height: 390px !important;
}

.mat-autocomplete-2,
.mat-autocomplete-3,
.mat-autocomplete-4,
.mat-autocomplete-5,
.mat-autocomplete-6,
.mat-autocomplete-7 {
  max-height: 320px !important;
}

.drivers-list-sidenav-container {
  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0);
  }
}

.cdk-overlay-container .cdk-overlay-pane .order-form-select-panel {
  max-height: 480px !important;
  background: $primary;
  .mat-option-text {
    color: $theme-white;
  }
  .mat-active {
    background: rgba($accent, 0.4);
  }
  .tariff-option {
    height: 38px;
    line-height: 38px;
  }

  mat-pseudo-checkbox {
    border-color: $theme-white;
  }
}

.cdk-overlay-container .cdk-overlay-pane .panel-fix-day {
  min-width: 256px !important;
  max-width: 256px !important;
  width: 256px;
  background-color: #142E43;
  max-height: fit-content;
  border-radius: 8px;
}

.cdk-overlay-pane:has(.mat-select-panel-wrap > .multiple-select-content-role-permissions) {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.cdk-overlay-pane:has(.mat-select-panel-wrap > .multiple-select-content-user-roles) {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.cdk-overlay-pane:has(.mat-select-panel-wrap > .select-support-operators) {
  //left: 50% !important;
  top: 50% !important;
  .mat-option {
    width: 100%;
  }
  //transform: translate(-50%, -50%) !important;
}



.addresses-autocomplete {
  .mat-option:hover:not(.mat-option-disabled) {
    .mat-option-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        overflow: hidden;
      }
      .clear-address-icon {
        display: inline-block !important;
      }
    }
  }
}

.option-brand {
  .mat-option-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    span {
      width: 92%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.panel-70 {
  max-height: 70vh !important;
}

.search-disp {
  &.input-white {
    height: 18px;

    mat-icon {
      color: #497188;
      width: 14px;
      height: 14px;
      font-size: 14px;
      margin-right: 6px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
      height: inherit;

      .mat-form-field-flex {
        height: inherit;
        display: flex;
        align-items: center;

        .mat-form-field-prefix {
          height: inherit;
          display: flex;
          align-items: center;
          justify-content: center;

          & > span {
            display: flex;
            position: relative;
            top: 1px;
          }
        }

        .mat-form-field-infix {
          padding: 0;
          margin: 0 !important;
          height: inherit;
          border: none;
          width: 100px;

          .mat-form-field-label-wrapper {
            font-size: 12px;
            line-height: 14px;

            .mat-form-field-label {
              top: 1.2em;

              mat-label {
                color: #497188;
                letter-spacing: 1px;
              }
            }
          }

          & > input {
            height: inherit;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 1px;

            &::placeholder {
              letter-spacing: 1px;
            }
          }
        }
      }

      .mat-form-field-underline {
        bottom: -4px;
        background-image: linear-gradient(to right, #BCCDD6 0%, #BCCDD6 100%, transparent 0%) !important;
      }

      .mat-form-field-subscript-wrapper {
        bottom: 0;
        top: inherit;
      }
    }
  }

  &.rebinding-input {
    height: 36px;

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          width: 230px;

          .mat-form-field-label-wrapper {
            padding-top: 14px;

            .mat-form-field-label {
              top: 18px;

              mat-label {
                color: #7FA2B8;
                font-size: 14px;
              }
            }
          }

          & > input {
            color: #ffffff;
            font-size: 16px;
            margin-top: 3px;
          }
        }
      }

      .mat-form-field-underline {
        bottom: 0;
        background-image: linear-gradient(to right, #7FA2B8 0%, #7FA2B8 100%, transparent 0%) !important;
      }
    }
  }
  &.passenger-blacklist-filter-input {
    height: 36px;
    max-width: 230px;

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          width: 230px;

          .mat-form-field-label-wrapper {
            padding-top: 14px;

            .mat-form-field-label {
              top: 18px;

              mat-label {
                color: #7FA2B8;
                font-size: 14px;
              }
            }
          }

          & > input {
            color: #ffffff;
            font-size: 16px;
            margin-top: 3px;
          }
        }
      }

      .mat-form-field-underline {
        bottom: 0;
        background-image: linear-gradient(to right, #7FA2B8 0%, #7FA2B8 100%, transparent 0%) !important;
      }
    }
  }
}

.request-form-field {
  color: white;
  .mat-form-field-label {
    color: #7FA2B8;
    letter-spacing: 1px;
    .ng-star-inserted {
      color: #7FA2B8;

    }
  }
  .mat-form-field-underline {
    background-color: #497188 !important;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }
  input, textarea {
    caret-color: $theme-white;
  }
}
.request-form-field.mat-focused {
  .mat-form-field-label {
    color: $theme-white;
  }
  .mat-form-field-underline {
    background-color: $theme-white;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }
}

.request-form-field-white {
  color: white;
  input, textarea {
    caret-color: white;
  }
  .mat-form-field-label {
    color: white;
    letter-spacing: 1px;
  }
  .mat-form-field-underline {
    background-color: white !important;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }

}
.request-form-field-white.mat-focused {
  .mat-form-field-label {
    color: $theme-white;
  }
  .mat-form-field-underline {
    background-color: $theme-white;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }
}
.request-form-field-white-additional.mat-focused  {
  .mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-color: white;
  }
}


.request-form-field-tools {
  color: white;
  input {
    caret-color: white;
  }
  .mat-form-field-label {
    color: #7FA2B8;
    font-size: 14px;
  }
  .mat-form-field-underline {
    background-color: #497188 !important;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }

  .mat-form-field-infix {
    padding: 0 0 5px 0;
    border-top: 1.5em solid transparent;
  }
}
.request-form-field-tools.mat-focused {
  .mat-form-field-label {
    color: $theme-white;
  }
  .mat-form-field-underline {
    background-color: $theme-white;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }
}
.request-item-form-field {
  color: white;
  .mat-form-field-label {
    color: #7FA2B8;
    letter-spacing: 1px;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }
}
.request-item-form-field.mat-focused {
  .mat-form-field-label {
    color: $theme-white;
  }
  .mat-form-field-underline {
    background-color: $theme-white;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }
}
.request-item-form-field.mat-form-field-disabled {
  color: #7FA2B8 !important;
  .mat-form-field-label, .mat-select-value-text, .mat-select-arrow {
    color: #7FA2B8 !important;
  }
  .mat-form-field-underline {
    background-color: #7FA2B8 !important;
    background-image: none;
  }
  .mat-form-field-ripple {
    background: #7FA2B8 !important;
  }
}
.request-form-field-table {
  color: white;
  width: 100%;
  .mat-form-field-label {
    color: #7FA2B8 !important;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .mat-form-field-underline {
    background-color: #7FA2B8 !important;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }

}
.request-form-field-table.mat-focused {
  .mat-form-field-label {
    color: $theme-white;
  }
  .mat-form-field-underline {
    background-color: $theme-white;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }
}
.driver-form-field-table {
  color: white;
  width: 100%;
  .mat-form-field-label {
    color: white !important;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .mat-form-field-underline {
    background-color: white !important;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }

}
.driver-form-field-table.mat-focused {
  .mat-form-field-label {
    color: $theme-white;
  }
  .mat-form-field-underline {
    background-color: $theme-white;
  }
  .mat-form-field-ripple {
    background: $theme-white;
  }
}

.mat-select-panel.custom-brand-select {
  background: #1C334A;
  border-radius: 8px;

  .mat-option {
    border-top: 1px solid rgba(108, 216, 222, 0.1);
    letter-spacing: 0.03em;
    font-size: 12px;
    line-height: 14px;
    height: 42px;

    &:hover {
      background-color: #132940;
    }

    &.filter-option {
      display: flex;
      align-items: center;
      height: 53px;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      background: #1C334A;
      z-index: 1;

      .mat-option-text {
        display: flex;

        & > input {
          border-bottom: 1px solid #fff;
          height: 32px;
          font-size: 12px;
          line-height: 14px;

          &::placeholder {
            color: #D0D8DE;
            letter-spacing: 0.03em;
            opacity: 0.2;
          }
        }
      }
    }
  }
}

.mat-select-panel.custom-select {
  background: #1C334A;
  border-radius: 8px;

  .mat-option {
    border-top: 2px solid #132940;;
    letter-spacing: 0.03em;
    font-size: 12px;
    line-height: 14px;
    height: 42px;

    &:hover {
      background-color: #132940;
    }
    &:first-child {
      border-top: none;
    }

    &.filter-option {
      display: flex;
      align-items: center;
      height: 53px;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      background: #1C334A;
      z-index: 1;

      .mat-option-text {
        display: flex;

        & > input {
          border-bottom: 1px solid #fff;
          height: 32px;
          font-size: 12px;
          line-height: 14px;

          &::placeholder {
            color: #D0D8DE;
            letter-spacing: 0.03em;
            opacity: 0.2;
          }
        }
      }
    }
  }
}
.mat-autocomplete-custom {
    background: #1C334A;
    border-radius: 8px;

    .mat-option {
      border-top: 2px solid #132940;;
      letter-spacing: 0.03em;
      font-size: 12px;
      line-height: 14px;
      height: 42px;

      &:hover {
        background-color: #132940;
      }
      &:first-child {
        border-top: none;
      }

      &.filter-option {
        display: flex;
        align-items: center;
        height: 53px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background: #1C334A;
        z-index: 1;

        .mat-option-text {
          display: flex;

          & > input {
            border-bottom: 1px solid #fff;
            height: 32px;
            font-size: 12px;
            line-height: 14px;

            &::placeholder {
              color: #D0D8DE;
              letter-spacing: 0.03em;
              opacity: 0.2;
            }
          }
        }
      }
    }
}

* {
  &::-webkit-scrollbar {
    width: 0.35em;
    height: 0.35em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $accent;
  }

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 2px rgba($accent, 0.3);
  }
}

.phone-stop-icon {
  svg {
    path {
      fill: #d87598;
    }
  }
}

.phone-send-clock-icon {
  svg {
    path {
      fill: #5cdac1;
    }
  }
}

.talk-icon {
  svg {
    path {
      fill: #f7df6d;
    }
  }
}

.filter-roles-panel {
  min-width: auto !important;
  border-radius: 8px;

  .mat-option {
    background-color: #1C334A;

    & > .mat-pseudo-checkbox {
      border: 1px solid #497188;
      border-radius: 2px;

      &.mat-pseudo-checkbox-checked {
        background-color: #22B4BF;
        border: 2px solid transparent;
      }
    }

    .mat-option-text {
      color: #BCCDD6;
      letter-spacing: 0.03em;
    }

    &:first-child,
    &:nth-of-type(2n) {
      background-color: #132940;
    }

    &:first-child {
      height: 60px;
    }
  }

  .filter-option {
    & > .mat-pseudo-checkbox {
      display: none !important;
    }

    .mat-list-item-content {
      padding: 0 10px !important;
      font-size: 0.875rem !important;
    }

    .mat-select-trigger {
      font-size: 1rem !important;
    }

    .mat-option-text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > button {
        border: 1px solid #497188;
        background: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        line-height: 32px;

        .mat-button-wrapper {
          display: flex;
          align-items: center;
        }

        .mat-checkbox {
          margin-right: 5px;
          cursor: pointer;

          .mat-checkbox-frame {
            border: 1px solid #497188;
            border-radius: 2px;
          }

          &.mat-checkbox-checked {
            .mat-checkbox-background {
              background-color: #22B4BF !important;
            }
          }
        }

        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
}

.commission-type-apply-panel {
  min-width: auto !important;
  border-radius: 8px;

  .mat-option {
    //background-color: #1C334A;

    & > .mat-pseudo-checkbox {
      border: 1px solid #497188;
      border-radius: 2px;

      &.mat-pseudo-checkbox-checked {
        background-color: #22B4BF;
        border: 2px solid transparent;
      }
    }
    //
    //.mat-option-text {
    //  color: #BCCDD6;
    //  letter-spacing: 0.03em;
    //}
    //
    //&:first-child,
    //&:nth-of-type(2n) {
    //  background-color: #132940;
    //}
    //
    //&:first-child {
    //  height: 60px;
    //}
  }

  .filter-option {
    & > .mat-pseudo-checkbox {
      display: none !important;
    }

    .mat-list-item-content {
      padding: 0 10px !important;
      font-size: 0.875rem !important;
    }

    .mat-select-trigger {
      font-size: 1rem !important;
    }

    .mat-option-text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > button {
        border: 1px solid #497188;
        background: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        line-height: 32px;

        .mat-button-wrapper {
          display: flex;
          align-items: center;
        }

        .mat-checkbox {
          margin-right: 5px;
          cursor: pointer;

          .mat-checkbox-frame {
            border: 1px solid #497188;
            border-radius: 2px;
          }

          &.mat-checkbox-checked {
            .mat-checkbox-background {
              background-color: #22B4BF !important;
            }
          }
        }

        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
}


.recent-channel-rating-panel {
  min-width: 170px !important;
  border-radius: 8px;
  &::-webkit-scrollbar {
    background-color: #132940;
  }

  .mat-option {
    background-color: #1C334A;
    border-bottom: 2px solid #132940;
    &:hover {
      background-color: #1C334A;
    }

    .mat-option-text {
      color: white;
      letter-spacing: 0.03em;
    }

    &:first-child, {
      background-color: #132940;
    }

    &:first-child {
      height: 31px;
      padding: 0;
      position: sticky;
      top: 0;
      z-index: 10;
      .mat-option-text {
        display: flex;
        justify-content: flex-end;
        .close-button {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          color: #FFFFFF;
          margin-right: 7px;
        }
      }
    }
  }

  .filter-option {
    .mat-list-item-content {
      padding: 0 10px !important;
      font-size: 0.875rem !important;
    }

    .mat-select-trigger {
      font-size: 1rem !important;
    }

    .mat-option-text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .disable-option {
    .mat-list-item-content {
      padding: 0 10px !important;
      font-size: 0.875rem !important;
    }

    .mat-select-trigger {
      font-size: 1rem !important;
    }

    .mat-option-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.brigade-panel {
  min-width: 170px !important;
  border-radius: 8px;
  margin-top: 40px;
  margin-left: -40px;
  &::-webkit-scrollbar {
    background-color: #132940;
  }

  .mat-option {
    background-color: #1C334A;
    border-bottom: 2px solid #132940;
    &:hover {
      background-color: #1C334A;
    }

    .mat-option-text {
      color: white;
      letter-spacing: 0.03em;
    }

    &:first-child, {
      background-color: #132940;
    }

    &:first-child {
      height: 31px;
      padding: 0;
      position: sticky;
      top: 0;
      z-index: 10;
      .mat-option-text {
        display: flex;
        justify-content: flex-end;
        .close-button {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          color: #FFFFFF;
          margin-right: 7px;
        }
      }
    }
  }

  .filter-option {
    .mat-list-item-content {
      padding: 0 10px !important;
      font-size: 0.875rem !important;
    }

    .mat-select-trigger {
      font-size: 1rem !important;
    }

    .mat-option-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .disable-option {
    .mat-list-item-content {
      padding: 0 10px !important;
      font-size: 0.875rem !important;
    }

    .mat-select-trigger {
      font-size: 1rem !important;
    }

    .mat-option-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

// mat-select-panel
.multiple-select-content-role-permissions {
  max-width: calc(300%) !important;
}
.multiple-select-content-user-roles {
  max-width: calc(200%) !important;
  min-width: 600px !important;
}

.merchant-panel {
  min-width: 600px !important;
}


//end mat-select-panel

.request-dialog-container .mat-dialog-container {
  padding: 16px;
  background-color: #355366;
  width: 100%;
  height: 100%;
  input, textarea {
    caret-color: white;
  }
}

.request-history-dialog-container .mat-dialog-container {
  background: #091F36;
  overflow: hidden;
  padding: 0;
}
.request-reject-dialog-container .mat-dialog-container {
  background: #091F36;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
}
.request-activate-dialog-container .mat-dialog-container {
  background: #091F36;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
}
.request-dialog-backdrop {
  background: rgba(15, 33, 52, 0.95);
}
.request-dialog-backdrop-transparent {
  background: transparent;
}
.request-status-select {
  min-height: 288px;
}

.reports-tabs{
  height: 48px;
  border: none;
  .mat-tab-link-container{
    .mat-tab-list {
      .mat-ink-bar {
        height: 1px !important;
        background-color: $accent !important;
      }
    }
  }
  .mat-tab-link {
    color: white;
    opacity: 1;
  }
}
.passenger-tabs{
  height: 100%;
  .mat-tab-header {
    height: 60px;
    border: none;
    background: rgba(48, 75, 92, 1);
    border-radius: 8px 8px 0 0;
  }

  .mat-tab-label-container{
    .mat-tab-list {
      .mat-ink-bar {
        height: 1px !important;
        bottom: 15px;
        background-color: $accent !important;
      }
    }
  }
  .mat-tab-label {
    color: white;
    opacity: 1;
  }
}
.driver-tabs{
  border: none;
  height: 100%;
  .mat-tab-body-wrapper {
    height: 100%;
  }
  .mat-tab-header {
    border-bottom: none;
    padding-bottom: 12px;
    background: #304B5C;
  }
  .mat-tab-label-container{
    .mat-tab-list {
      .mat-ink-bar {
        height: 1px !important;
        background-color: $accent !important;
      }
    }
  }
  .mat-tab-label {
    width: 358px;
    color: white;
    opacity: 1;
  }
}

.search-vehicle-dialog-container-background {
  background: rgba(15, 33, 52, 0.90);
}

.logout-menu {
  background: #132940 !important;
  .mat-menu-content {
    min-width: 202px !important;
    .mat-menu-item {
      color: white !important;
      background: #1C334A !important;
      margin-bottom: 4px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .mat-menu-content:not(:empty) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.slide-driver-toggle {
  .mat-slide-toggle-bar {
    background: #63869C;
  }
  .mat-slide-toggle-thumb {
    background: #7FA2B8;
  }
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
  border: none;
}

.option-not-found {
  .mat-option-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: transparent !important;
  }
}

.mat-list-adding-cars {
  .mat-list-item-content {
    padding: 0 !important;
  }
}
.dismissal-tabs{
  height: 100%;
  .mat-tab-header {
    border-bottom-color: transparent !important;
  }
  .mat-tab-body-content {
  }
  .mat-tab-label {
    padding: 0 !important;
    min-width: 130px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }
  .mat-tab-label-container {
    margin-bottom: 16px;
  }
  .mat-ink-bar {
    height: 1px !important;
    background-color: $accent !important;
  }
}

.date-range-picker {
  .mat-date-range-input-separator {
    color: white;
  }
}

mat-list-item:hover {
  .work-in-taxi {
    color: $accent;

    mat-icon {
      svg {
        path {
          fill: $accent;
          stroke: $accent
        }
      }
    }
  }
}

.image-group-form {
  image-cropper {
    --cropper-outline-color: #4D6676;
    padding: 0;
    div {
      img {
        border-radius: 8px;
      }
    }
    .ngx-ic-cropper {
      &::after {
        opacity: 0 !important;
      }
    }
  }
}

.status-connection-tooltip {
  background: rgba(100, 100, 100, 1);
  font-size: 12px;
  line-height: 16px;
}

.loading > span.mat-option-text {
  display: flex;
  justify-content: center;
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: $accent;
  }
}

.mat-icon {
  overflow: visible !important;
}

.operator-warn-tooltip {
  border-radius: 4px;
  background: #E46273;
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mat-tooltip {
  font-size: 11px !important;
}

.address-vertical-step {
  .mat-step-header {
    padding-right: 0 !important;
  }
}

.more-menu-dispatcher {
  border-radius: 8px !important;
  background: #295665 !important;

  .mat-menu-content {
    gap: 2px !important;
    padding: 0 !important;
  }
  .item-menu-more {
    color: #fff;
    cursor: pointer;
    background: #2F5C6B;

    .icon-for-menu {
      color: white;
    }
    span {
      font-size: 17px;
    }
    &:hover {
      color: $accent;
      .icon-for-menu {
        color: $accent;
        svg {
          path {
            fill: $accent;
          }
        }
      }
      .only-stroke {
        color: $accent;
        svg {
          path {
            stroke: $accent;
          }
        }
      }
    }
  }

}

.control-chat-dispatcher {
  border-radius: 8px !important;
  background: #295665 !important;
  max-width: fit-content !important;
  min-height: auto !important;

  .mat-menu-content {
    gap: 2px !important;
    padding: 0 !important;
  }
  .item-menu-more {
    color: #fff;
    cursor: pointer;
    background: #2F5C6B;

    .icon-for-menu {
      color: white;
    }
    span {
      font-size: 17px;
    }
    &:hover {
      color: $accent;
    }
  }

}

.checkbox-theme {
  .mat-checkbox-frame {
    border-color: #497188 !important;
  }
}

.mat-accordion {
  .expansion-panel-operator-comment {
    background: #314E5D;
    box-shadow: none;
    .mat-expansion-panel-body {
      padding: 0 !important;
      border-bottom: 1px solid #405F73;;
      margin-bottom: 24px;
      max-height: 226px;
      overflow-y: auto;
    }
  }
}

.form-operator-comment {
  .request-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        align-items: center !important;
      }
    }
  }
}

.autocomplete-comment-panel {
  background: #295665;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-height: 158px !important;
  .mat-option {
    min-height: 51px;
    background: #2F5C6B;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    color: white;
  }
}
.accent-color {
  color: $accent ;
}

.order-panel-history {
  background: #314E5D !important;
  box-shadow: none !important;
  .mat-expansion-panel-header {
    padding: 0 16px !important;
    .mat-expansion-panel-header-title {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      .time {
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        letter-spacing: 0;
        text-align: center;
        color: white;
      }
      .type {
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        letter-spacing: 0;
        text-align: center;
        color: white;
      }
      .causer-person {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;
        color: white;
      }
    }
    .mat-expansion-indicator::after {
      color: $accent;
    }
  }
  .mat-expansion-panel-body {
    padding-right: 16px !important;
    padding-left: 16px !important;
    padding-bottom: 0 !important;
  }
}
.order-panel-history-expanded {
  background: #425F71 !important;
  .mat-expansion-panel-header {
    padding: 12px 16px !important;
  }
}

.history-operator-comment-tab {
  .mat-tab-body-content {
    position: relative;
  }
}

.no-bottom-padding-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}
 @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .no-bottom-padding-form-field-safari {
    .mat-form-field-wrapper {
      padding-bottom: 1.25em;
    }
  }
}



.autocolumn-inner-table-field {
  input {
    caret-color: white;
  }
}

.mat-column-name, .mat-column-loc_name {
  .autocolumn-inner-table-field, .mat-form-field {
    .mat-form-field-infix {
      width: 100px !important;
    }
  }
}

.mat-column-priority,
.mat-column-automat_sending_priority,
.mat-column-base_driver_rating,
.mat-column-low_driver_rating,
.mat-column-warning_driver_rating,
.mat-column-driver_rating_trip_rejected,
.mat-column-driver_rating_request_completed,
.mat-column-driver_rating_next_request_completed,
.mat-column-driver_rating_hidden_request_completed,
.mat-column-boost_driver_rating,
.mat-column-skill_boost_driver_rating,
.mat-column-driver_rating_card_request_completed,
.mat-column-hidden_mode_boost_driver_rating,
.mat-column-driver_rating_filtered_trip_rejected
{
  .autocolumn-inner-table-field {
    input {
      text-align: center;
    }
    .mat-form-field-infix {
      width: 50px !important;
    }
  }
}

.popover-commission-scale.mtx-popover-panel {
  padding: 0;
  border-radius: 8px;
}

.popover-commission-reduce.mtx-popover-panel {
  padding: 0;
  border-radius: 8px;
  .mtx-popover-direction-arrow {
    &::after {
      border-color: #5C5F60;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }
}

.popover-driver-form.mtx-popover-panel {
  padding: 0;
  border-radius: 8px;
  max-width: 218px;
  .mtx-popover-direction-arrow {
    display: none;
    &::after {
      content: '';
    }
  }
}

.radio-group-setting-block {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 16px;
  .mat-radio-outer-circle{
    border-color: $new-primary;
  }
}

.checkbox-group-setting-block {
  display: flex !important;
  flex-direction: column;
  gap: 12px;
  color: white;
}

.no-pointer-events {
  pointer-events: none;
}

.custom-quill-editor {
  width: 100%;
  height: 100%;
  display: block !important;
  .ql-toolbar.ql-snow {
    width: 100%;
    border: 1px solid #30475E;
    border-radius: 8px 8px 0 0;
    background: #263D54;
  }
  .ql-container.ql-snow {
    height: calc(100% - 42px);
    border: 1px solid #30475E;
    border-radius: 0 0 8px 8px;
    background: #263D54;
  }
  .ql-toolbar .ql-stroke {
    fill: none;
    stroke: #fff;
  }

  .ql-toolbar .ql-fill {
    fill: #fff;
    stroke: none;
  }

  .ql-toolbar .ql-picker {
    color: #fff;
  }

  .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $accent;
  }
  .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: $accent;
  }
  .ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: $accent;
  }
  .ql-snow .ql-picker-options {
    background: #30475E;
  }
  .ql-toolbar.ql-snow .ql-picker-label {
    //border: 1px solid #30475E;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border: 1px solid #30475E;
  }
  .ql-editor {
    color: white;
  }
}

.tooltip-text-device {
  white-space: pre-line;
}
