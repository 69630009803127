@import 'variables.scss';

.cab-marker {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  transform: translateY(-10px);
}

.cab-title {
  font-size: 18px;
  height: 21px;
  text-align: center;
  line-height: 1.17;
  letter-spacing: 0.6px;
  color: #142E43;
  font-family: Roboto;
  font-weight: bold;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 12px 5px;
  border-color: transparent transparent #083750 transparent;
}

.triangle-container {
  margin: 3px;
}

.cab-color-yellow {
  border: 2px solid rgba(200, 132, 66, 1);
}

.cab-color-green {
  border: 2px solid rgba(51, 127, 34, 1);
}

.cab-color-red {
  border: 2px solid rgba(200, 66, 66, 1);
}

.cab-color-black {
  border: 2px solid black;
}

.cab-selected {
  background: #142E43;
}

.title-cab-selected {
  color: #fff;
}

.triangle-cab-selected {
  border-color: transparent transparent #fff transparent;
}

.map-marker {
  background-image: url("assets/imgs/icons/utax_pin.svg");
  background-size:100%;
  width: 50px;
  height: 50px;
  transform: translate(-25px, -35px);
}

.caption {
  color: $primary;
  text-align: center;
  padding-top: 13px;
}
